import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRestaurantDetailsPublicById } from '../../../swr';
import { ApiHelpers } from '../../../helpers';
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font, Image, usePDF } from '@react-pdf/renderer';
import { Button, Space, Tree, Skeleton, Row, Col, Checkbox, Popover, Radio, Alert, Switch } from 'antd';
import { DownloadOutlined, DeliveredProcedureOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { activeEnvironment } from '../../../config';
import MenuPrintDocx from './Print/MenuPrintDocx';
import { nutritionalMenuFull, nutritionalMenuShort, nutritionalMenuShort2 } from './Print/MenuItemPrint';

const proxyURL = 'https://proxy-vhillsioda-uc.a.run.app/';
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 9,
        fontFamily: 'Nunito',
    },
    section: {
        margin: 8,
        padding: 8,
        flexGrow: 1,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#292333',
        marginBottom: 25,
    },
    subtitle: {
        fontSize: 10,
        marginBottom: 10,
        fontFamily: 'Oswald',
        textAlign: 'center',
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: 10,
    },
    section: {
        marginBottom: 25,
        paddingBottom: 10,
    },
    sectionInner: {
        marginBottom: 8,
    },
    menuItem: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderBottom: 1,
        borderBottomColor: '#efefef',
        paddingBottom: 8,
    },
    menuItemTitle: {
        fontWeight: 600,
        fontSize: 12,
        marginBottom: 3,
        fontFamily: 'Nunito',
    },
    menuCategoryTag: {
        fontSize: 10,
        textAlign: 'center',
        marginBottom: 70,
        fontFamily: 'Roboto',
        fontWeight: 300,
    },
    menuItemImage: {
        borderRadius: 10,
        objectFit: 'cover',
        width: 100,
        height: 60,
    },
    menuItemImageContainer: {
        width: 100,
        height: 60,
        borderRadius: 10,
        marginRight: 20,
    },
    menuItemPrice: {
        textAlign: 'right',
        flex: 1,
        fontSize: 11,
    },
    menuItemText: {
        flex: 3,
    },

    textHighlight: {
        fontWeight: 700,
    },
    nutritionalSectionTitle: {
        marginTop: 5,
        marginBottom: 3,
    },
    small: {
        fontSize: 7,
        marginTop: 7,
        fontWeight: 300,
    },
    verticalSpacingSmall: {
        marginTop: 3,
    },
});

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
const options = [
    { label: 'Rezumat', value: 'short' },
    { label: 'Completă', value: 'full' },
];

Font.register({
    family: 'Roboto',
    fonts: [
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 600 },
    ],
});

Font.register({
    family: 'Nunito',
    fonts: [
        { src: 'https://fonts.cdnfonts.com/s/15604/Nunito-Regular.woff', fontWeight: 400 },
        { src: 'https://fonts.cdnfonts.com/s/15604/Nunito-SemiBold.woff', fontWeight: 600 },
        { src: 'https://fonts.cdnfonts.com/s/15604/Nunito-Bold.woff', fontWeight: 700 },
    ],
});

const tooltipNutritionalStyle = {
    title: '',
    text: 'Afișarea rezumat este suficientă conform normelor ordinului ANPC 201/2022. Opțional, poți alege afișarea completă',
};

const tooltipShowIngredientQty = {
    title: '',
    text: 'Conform Ordinului ANPC 201/2022 aveți obligația de a avea minim un meniu fizic la locație cu varianta extinsă. Și ingredientele ce depășesc 20 de grame să aibe  gramajul afișat.',
};

const MenuPrint = (props) => {
    let { restaurantId } = useParams();
    const { restaurantTitle } = props;
    const [isPrintLoading, setIsPrintLoading] = useState(true);
    const [itemsInMenuCategoryTag, setItemsInMenuCategoryTag] = useState({
        food: 0,
        beverages: 0,
        tobacco: 0,
    });
    const [menuFilter, setMenuFilter] = useState(null);
    const { data: restaurantDetails, isLoading } = useRestaurantDetailsPublicById(restaurantId);
    const [treeData, setTreeData] = useState([]);
    const [selectedMenuCategories, setSelectedMenuCategories] = useState(null);
    const [restaurantMenu, setRestaurantMenu] = useState();
    const [nutritionalStyle, setNutritionalStyle] = useState('short');
    const [customization, setCustomization] = useState({
        showIngredientQty: false,
    });

    const onChange3 = ({ target: { value } }) => {
        setMenuFilter(false);
        setNutritionalStyle(value);
    };

    const onChangeSwitch = (checked, field) => {
        setCustomization({ ...customization, showIngredientQty: checked });
    };
    const renderItemDetails = (menuItem, index, nutritionalStyle) => {
        const hasListOfIngredients = (menuItem) => menuItem.listOfIngredients && menuItem.listOfIngredients.length > 0;
        return (
            <View key={'menuItem' + menuItem.menuItemToken} style={styles.sectionInner} wrap={false}>
                <View style={styles.menuItem}>
                    {menuItem.itemImagePath ? (
                        <View style={styles.menuItemImageContainer}>
                            <Image
                                source={{
                                    uri:
                                        activeEnvironment === 'live'
                                            ? proxyURL + ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'small')
                                            : ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'small'),
                                    method: 'GET',
                                    headers: {
                                        'Cache-Control': 'no-cache',
                                    },
                                }}
                                style={styles.menuItemImage}
                            />
                        </View>
                    ) : null}

                    <View style={styles.menuItemText}>
                        {!hasListOfIngredients(menuItem) ? (
                            <View>
                                <Text style={styles.menuItemTitle}>{menuItem.title}</Text>
                                <Text>{menuItem.weight + (menuItem.itemDescription ? ' | ' + menuItem.itemDescription : '')}</Text>
                            </View>
                        ) : null}
                        {!hasListOfIngredients(menuItem) ? (
                            !menuItem.listOfIngredients ? (
                                <Text>{menuItem.ingredients}</Text>
                            ) : null
                        ) : (
                            <View>
                                {nutritionalStyle === 'short'
                                    ? nutritionalMenuShort2(menuItem.listOfIngredients, menuItem, true)
                                    : nutritionalMenuFull(menuItem.listOfIngredients, menuItem, true, customization.showIngredientQty)}
                            </View>
                        )}
                    </View>
                    <View style={styles.menuItemPrice}>
                        <Text>{menuItem.price.toFixed(2)} lei</Text>
                    </View>
                </View>
            </View>
        );
    };

    const renderItem = (menuCategory, index, nutritionalStyle, customization) => {
        let firstItem = null;
        let nextItems = null;
        let counter = nutritionalStyle === 'short' ? 2 : 1;
        if (menuCategory.item && menuCategory.item.length > 0) {
            firstItem = menuCategory.item.slice(0, counter);
            nextItems = menuCategory.item.slice(counter);
            return (
                <View style={styles.section} key={'menuCategory' + index}>
                    <View wrap={false}>
                        <Text style={styles.subtitle}>{menuCategory.categoryName}</Text>
                        {firstItem.map((menuItem, index) => {
                            return renderItemDetails(menuItem, index, nutritionalStyle, customization);
                        })}
                    </View>
                    {nextItems.map((menuItem, index) => {
                        return renderItemDetails(menuItem, index + counter, nutritionalStyle, customization);
                    })}
                </View>
            );
        }
    };

    useEffect(() => {
        const newTree = [];
        if (restaurantDetails && restaurantDetails.menu) {
            if (restaurantDetails.menu[0].category) {
                setItemsInMenuCategoryTag({
                    food: restaurantDetails.menu[0].category.filter((d) => d.englishTag === 'food').length,
                    beverages: restaurantDetails.menu[0].category.filter((d) => d.englishTag === 'beverages').length,
                    tobacco: restaurantDetails.menu[0].category.filter((d) => d.englishTag === 'tobacco').length,
                });

                if (restaurantDetails.menu[0].category.filter((d) => d.englishTag === 'food').length > 0)
                    newTree.push({
                        title: 'Mancare',
                        englishTag: 'food',
                        key: 'food',
                        children: [],
                    });
                if (restaurantDetails.menu[0].category.filter((d) => d.englishTag === 'beverages').length > 0)
                    newTree.push({
                        title: 'Bauturi',
                        englishTag: 'beverages',
                        key: 'beverages',
                        children: [],
                    });
                if (restaurantDetails.menu[0].category.filter((d) => d.englishTag === 'tobacco').length > 0)
                    newTree.push({
                        title: 'Tutun',
                        englishTag: 'tobacco',
                        key: 'tobacco',
                        children: [],
                    });

                restaurantDetails.menu[0].category.map((menuCategory) => {
                    newTree
                        .filter((d) => d.englishTag === menuCategory.englishTag)[0]
                        .children.push({
                            title: menuCategory.categoryName,
                            key: menuCategory.menuCategoryToken,
                        });
                });
                setTreeData([...newTree]);
                setRestaurantMenu(restaurantDetails.menu[0]);
            }
        }
    }, [restaurantDetails]);

    const MyDoc = () => (
        <Document>
            {itemsInMenuCategoryTag.food > 0 &&
            (selectedMenuCategories !== null
                ? selectedMenuCategories.checkedNodes.filter((d) => d.key === 'food').length > 0 ||
                  selectedMenuCategories.halfCheckedKeys.filter((d) => d === 'food').length > 0
                : true) ? (
                <Page size="A4" style={styles.body}>
                    <Text style={styles.title}>{restaurantTitle}</Text>
                    <View>
                        {restaurantDetails.menu &&
                            restaurantDetails.menu[0].category &&
                            restaurantDetails.menu[0].category
                                .filter((d) => d.englishTag === 'food')
                                .map((menuCategory, index) => {
                                    if (
                                        selectedMenuCategories !== null
                                            ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length > 0
                                            : true
                                    )
                                        return renderItem(menuCategory, index, nutritionalStyle, customization);
                                })}
                    </View>
                    <View fixed>
                        <Text style={styles.small}>Ingredientele ingrosate contin sau pot contine alergeni. </Text>
                    </View>
                </Page>
            ) : null}
            {itemsInMenuCategoryTag.beverages > 0 &&
            (selectedMenuCategories !== null
                ? selectedMenuCategories.checkedNodes.filter((d) => d.key === 'beverages').length > 0 ||
                  selectedMenuCategories.halfCheckedKeys.filter((d) => d === 'beverages').length > 0
                : true) ? (
                <Page size="A4" style={styles.body}>
                    <View>
                        <Text style={styles.title}>{restaurantTitle}</Text>
                        {restaurantDetails.menu &&
                            restaurantDetails.menu[0].category &&
                            restaurantDetails.menu[0].category
                                .filter((d) => d.englishTag === 'beverages')
                                .map((menuCategory, index) => {
                                    if (
                                        selectedMenuCategories !== null
                                            ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length > 0
                                            : true
                                    )
                                        return renderItem(menuCategory, index, customization);
                                })}
                    </View>
                    <View fixed>
                        <Text style={styles.small}>Ingredientele ingrosate contin sau pot contine alergeni. </Text>
                    </View>
                </Page>
            ) : null}
            {itemsInMenuCategoryTag.tobacco > 0 &&
            (selectedMenuCategories !== null
                ? selectedMenuCategories.checkedNodes.filter((d) => d.key === 'tobacco').length > 0 ||
                  selectedMenuCategories.halfCheckedKeys.filter((d) => d === 'tobacoo').length > 0
                : true) ? (
                <Page size="A4" style={styles.body}>
                    <View>
                        <Text style={styles.title}>{restaurantTitle}</Text>
                        {restaurantDetails.menu &&
                            restaurantDetails.menu[0].category &&
                            restaurantDetails.menu[0].category
                                .filter((d) => d.englishTag === 'tobacco')
                                .map((menuCategory, index) => {
                                    if (
                                        selectedMenuCategories !== null
                                            ? selectedMenuCategories.checkedNodes.filter((d) => d.key === menuCategory.menuCategoryToken).length > 0
                                            : true
                                    )
                                        return renderItem(menuCategory, index, customization);
                                })}
                    </View>
                    <View fixed>
                        <Text style={styles.small}>Ingredientele ingrosate contin sau pot contine alergeni. </Text>
                    </View>
                </Page>
            ) : null}
        </Document>
    );

    const onChangeCheckbox = (event, field) => {};

    const onSelect = (selectedKeys, info) => {};
    const onLoad = (selectedKeys, info) => {};
    const onCheck = (checkedKeys, info) => {
        setSelectedMenuCategories({ ...info });

        setMenuFilter(false);
    };

    return isLoading !== true && restaurantDetails ? (
        restaurantDetails.statusCode !== 0 ? (
            <>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <h3>Alege categoriile</h3>
                        <div className="spacingBottom">
                            {treeData.length > 0 ? (
                                <Tree
                                    checkable
                                    selectable={false}
                                    defaultExpandedKeys={['']}
                                    defaultSelectedKeys={['']}
                                    defaultCheckedKeys={['food', 'beverages', 'tobacco']}
                                    onSelect={onSelect}
                                    onLoad={onLoad}
                                    onCheck={onCheck}
                                    treeData={treeData}
                                />
                            ) : (
                                'Nu există niciun preparat activ.'
                            )}
                        </div>
                        <h4>
                            Afișare valori nutriționale{' '}
                            <Popover
                                className="iconSpacing"
                                placement="right"
                                title={tooltipNutritionalStyle.title}
                                content={tooltipNutritionalStyle.text}
                                trigger="hover">
                                <InfoCircleOutlined />
                            </Popover>
                        </h4>
                        <div className="spacingBottom">
                            <Radio.Group options={options} onChange={onChange3} value={nutritionalStyle} optionType="button" />
                        </div>
                        {nutritionalStyle !== 'short' ? (
                            <div className="topSpacing">
                                <div className="spacingBottom">
                                    <Switch
                                        defaultChecked
                                        onChange={(checked) => onChangeSwitch(checked, 'text')}
                                        checked={customization.showIngredientQty}
                                    />
                                    Afișează gramaj ingrediente
                                    <Popover
                                        className="iconSpacing"
                                        placement="right"
                                        title={tooltipShowIngredientQty.title}
                                        content={tooltipShowIngredientQty.text}
                                        trigger="hover">
                                        <InfoCircleOutlined />
                                    </Popover>
                                </div>
                            </div>
                        ) : null}
                    </Col>
                </Row>
                <div className="spacingTop"></div>
                <Row>
                    {!menuFilter &&
                    (selectedMenuCategories !== null
                        ? selectedMenuCategories.checkedNodes.length > 0 || selectedMenuCategories.halfCheckedKeys.length > 0
                        : true) ? (
                        treeData.length > 0 ? (
                            <Space>
                                <Button type="primary" size="large" icon={<DeliveredProcedureOutlined />} onClick={() => setMenuFilter(true)}>
                                    Genereaza PDF
                                </Button>
                                {nutritionalStyle === 'short' ? (
                                    <MenuPrintDocx
                                        restaurantDetails={restaurantDetails}
                                        selectedMenuCategories={selectedMenuCategories}
                                        menuFilter={menuFilter}
                                        itemsInMenuCategoryTag={itemsInMenuCategoryTag}
                                        restaurantTitle={restaurantTitle}
                                    />
                                ) : null}
                            </Space>
                        ) : null
                    ) : null}
                </Row>

                {menuFilter ? (
                    <Space direction="vertical" size={20}>
                        <PDFDownloadLink document={<MyDoc />} fileName={'menu-' + restaurantTitle + '-' + moment().format('YYYY-MM-DD')}>
                            {({ blob, url, loading, error }) =>
                                loading ? (
                                    <Button size="large" loading={true}>
                                        Se incarca
                                    </Button>
                                ) : (
                                    <Button type="primary" size="large" icon={<DownloadOutlined />}>
                                        Descarca fisier
                                    </Button>
                                )
                            }
                        </PDFDownloadLink>

                        <PDFViewer height={1200} width={900}>
                            <MyDoc />
                        </PDFViewer>
                    </Space>
                ) : null}
            </>
        ) : null
    ) : (
        <Skeleton active />
    );
};

export default MenuPrint;

import React, { useState, useEffect } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import AdminLayout from './AdminLayout';
import { Spin, Tabs } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ApiHelpers } from '../../helpers';
import { activeEnvironment } from '../../config';
import MenuCategoryForm from './Menu/MenuCategoryForm';
import MenuItemForm from './Menu/MenuItemForm';

import * as ROUTES from '../../const/routes';
import { useRestaurantDetailsById, useMenuById } from '../../swr';
import MenuForm from './Menu/MenuForm';


const { TabPane } = Tabs;

function AdminMenu() {
    let { menuId, restaurantId } = useParams();

    const [menuCategoriesData, setMenuCategoriesData] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState("#categories");
    const [tableFilterMenuItems, setTableFilterMenuItems] = useState([]);
    const [viewFilter, setViewFilter] = useState(localStorage.getItem(activeEnvironment + '_viewFilter') || "active");

    const { data: restaurantDetails } = useRestaurantDetailsById(restaurantId);
    const { data: newMenuData, isLoading: isLoadingNewMenuData } = useMenuById(restaurantId, menuId);

    const navigate = useNavigate();
    const routeLocation = useLocation();
    const callbackTabs = (key) => {
        setActiveTabKey(key);
        localStorage.setItem(activeEnvironment + '_viewFilter', viewFilter);
        navigate(key);
    };

    useEffect(() => {
        // ApiHelpers.getMenuByUniqueCode(menuId)
        //   .then((response) => {
        //     setMenuData(response.data[0]);
        //   }, (error) => {
        //     console.log(error);
        //   });

        ApiHelpers.getMenuCategories(menuId).then(
            (response) => {
                setMenuCategoriesData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [menuId]);

    useEffect(() => {
        if (routeLocation.hash.indexOf('#items') > -1) {
            // Get the stored filter when switching to items tab
            const storedFilter = localStorage.getItem(activeEnvironment + '_viewFilter') || "active";
            setViewFilter(storedFilter);
            setActiveTabKey('#items');
        }
        if (routeLocation.hash.indexOf('#categories') > -1) {

            // Get the stored filter when switching to items tab
            const storedFilter = localStorage.getItem(activeEnvironment + '_viewFilter') || "active";
            setViewFilter(storedFilter);
            setActiveTabKey('#categories');
            setTableFilterMenuItems([]);
        }
        if (routeLocation.hash.indexOf('#details') > -1) {
            setActiveTabKey('#details');
        }
    }, [routeLocation.hash]);

    const breadcrumb = [{ label: restaurantDetails ? restaurantDetails.title : null, link: ROUTES.ADMIN_LOCATIONS + restaurantId }];

    return (
        <AuthUserContext.Consumer>
            {(authUser) =>
                !isLoadingNewMenuData ? (
                    <AdminLayout breadcrumb={breadcrumb} authUser={authUser} pageTitle={newMenuData[0].menuName}>
                        <div>
                            <h2>{newMenuData[0].menuName}</h2>
                            <Tabs activeKey={activeTabKey} defaultActiveKey={routeLocation.hash} onChange={callbackTabs}>
                                <TabPane tab="Detalii" key="#details">
                                    <MenuForm
                                        restaurantId={restaurantId}
                                        restaurantDetails={restaurantDetails}
                                        menuData={newMenuData[0]}
                                        actionType="edit"
                                        view="standard"
                                    />
                                </TabPane>
                                <TabPane tab="Categorii" key="#categories">
                                    <MenuCategoryForm
                                        menuId={newMenuData[0].uniqueIdentificationToken}
                                        menuData={newMenuData[0]}
                                        setActiveTabKey={setActiveTabKey}
                                        menuCategoriesData={menuCategoriesData}
                                        setMenuCategoriesData={setMenuCategoriesData}
                                    />
                                </TabPane>
                                <TabPane tab="Preparate" key="#items">
                                    <MenuItemForm
                                        menuId={newMenuData[0].uniqueIdentificationToken}
                                        menuData={newMenuData[0]}
                                        menuCategoriesData={menuCategoriesData}
                                        tableFilter={tableFilterMenuItems}
                                        setTableFilter={setTableFilterMenuItems}

                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    </AdminLayout>
                ) : (
                    <Spin />
                )
            }
        </AuthUserContext.Consumer>
    );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(AdminMenu);
